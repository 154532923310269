/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"); 

#header {
    height: 70px;
    transition: all 0.5s;
    transition: all 0.5s;
    padding: 10px 0;
    background: $color_white;
    z-index: 997;
  }
  
  #header.header-scrolled {
    box-shadow: $shadow_blackLight;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  #header .branlangdName ul {
    padding-left: 0;
    list-style: none;
    float: left!important;
  }
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  .nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    float: left!important;
  }
  
  .nav-menu a, .nav-menu i, .nav-menu ul span {
    display: block;
    position: relative;
    color: $color_blueLink;
    padding: 12px 15px;
    transition: 0.3s;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }
  
  .nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a,
  .nav-menu i:hover, .nav-menu li:hover > i{
    color: $color_blue;
    text-decoration: none;
  }

  .nav-menu .get-started .service_action {
    background: $color_blackBlue;
    color: $color_white;
    border-radius: 50px;
    margin: 0 15px;
    padding: 10px 25px;
  }
  
  .get-started, .lang{
    margin-left: -10px;

    ul{
      border-radius: 15px!important;

      li:first-child{
        a,span{
          border-top-right-radius: 15px!important;
          border-top-left-radius: 15px!important;
        }
      }
      li:last-child{
        a,span{
          border-bottom-right-radius: 15px!important;
          border-bottom-left-radius: 15px!important;
        }
      }
    }

    &.login{
      min-width: 133px;
    
      .service_action:after {
        margin-left: 5px;
        content: '\f105';
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900;
      }

      .service_action:hover:after {
        content: '\f107';
      }
      
    }
  }

  .lang{
    ul{
      width: 110px!important;
      margin-left: -10px;

      li{
        width: 110px!important;
        min-width: 110px!important;
      }
    }
  }

  .nav-menu .get-started a:hover, .nav-menu ul span:hover {
    background: $color_blackBlueHover;
    color: $color_white!important;
  }
  
  
  .nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 99;
    opacity: 0!important;
    visibility: hidden!important;
    background: $color_white;
    box-shadow: $shadow_dropDown;
    transition: 0.3s;
  }
  
  .nav-menu .drop-down:hover > ul {
    opacity: 1!important;
    top: 100%;
    visibility: visible!important;
  }
  
  .nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
  }
  
  .nav-menu .drop-down ul a, .nav-menu .drop-down ul span {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: $color_blueLink2; 
  }
  
  .nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a,
  .nav-menu .drop-down ul span:hover, .nav-menu .drop-down ul li:hover > span {
    color: $color_blue;
  }
  
  /* .nav-menu .drop-down > a:after, .nav-menu .drop-down i:after {
    margin-left: 5px;
    content: '\f105';
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900;
  }

  .nav-menu .drop-down:hover > a:after, .nav-menu .drop-down > i:hover:after {
    content: '\f107';
  } */
  
  
  .nav-menu .drop-down .drop-down ul {
    top: 0;
    left: 100%;
  }
  
  .nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
  }
  
  
  /* Mobile Navigation */
  .mobile-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    overflow-y: auto;
    left: -260px;
    width: 260px;
    padding-top: 18px;
    background: $color_blackBlueBackground;
    transition: 0.4s;
  }
  
  .mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .mobile-nav a {
    display: block;
    position: relative;
    color: $color_lightGray2;
    padding: 10px 20px;
    font-weight: 500;
  }
  
  .mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
    color: $color_lightBlue;
    text-decoration: none;
  }
  
  .mobile-nav .drop-down > a:after {
    content: '\f107'!important; 
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900;
    padding-left: 10px;
    position: absolute;
    right: 15px;
  }
  
  .mobile-nav .active.drop-down > a:after {
    content: "\f105"!important;
  }
  
  .mobile-nav .drop-down > a {
    padding-right: 35px;
  }
  
  .mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
    
    display: block;
    position: relative;
    color: $color_lightGray2;
    padding: 10px 20px;
    font-weight: 500;
  }
  
  .mobile-nav .drop-down li {
    padding-left: 20px;
  }
  
  .mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 20px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
  }
  
  .mobile-nav-toggle i {
    color: $color_blackBlue;
  }
  
  .mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: $color_blackBlueOpacity;
    overflow: hidden;
    display: none;
  }
  
  .mobile-nav-active {
    overflow: hidden;
  }
  
  .mobile-nav-active .mobile-nav {
    left: 0;
  }
  
  .mobile-nav-active .mobile-nav-toggle i {
    color: $color_white;
  }