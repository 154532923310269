/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact .info {
    padding: 30px;
    width: 100%;
    border-radius: 15px;
    background: $color_white;
    box-shadow: $shadow_cards;
  }
  
  .contact .info i {
    font-size: 20px;
    color: $color_white;
    float: left;
    width: 44px;
    height: 44px;
    background: $color_blackBlue;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: $color_titleFonts;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: $color_secondaryFonts;
  }
  