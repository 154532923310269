/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 100vh;//calc(100vh - 50px);
    padding: 0;
    overflow: hidden;
  }
  
  #hero .carousel-item {
    width: 100%;
    height: 100vh;//calc(100vh - 50px);
    background-size: cover;
    background-position: center center!important;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  
  #hero .carousel-item::before {
    content: '';
    background-color: $shadow_blackLight2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  #hero .carousel-content {
    text-align: left;
  }
  
  @media (max-width: 992px) {
    #hero, #hero .carousel-item {
      height: calc(100vh - 70px);
    }
    #hero .carousel-content.container {
      padding: 0 50px;
    }
  }
  
  #hero h2 {
    color: $color_white;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 900;
  }
  
  #hero p {
    width: 80%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    color: $color_white;
  }
  
  #hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
  }
  
  #hero .carousel-inner .carousel-item,
  #hero .carousel-inner .active.carousel-item-left,
  #hero .carousel-inner .active.carousel-item-right {
    opacity: 0;
  }
  
  #hero .carousel-inner .active,
  #hero .carousel-inner .carousel-item-next.carousel-item-left,
  #hero .carousel-inner .carousel-item-prev.carousel-item-right {
    opacity: 1;
    transition: 0.5s;
  }
  
  #hero .carousel-inner .carousel-item-next,
  #hero .carousel-inner .carousel-item-prev,
  #hero .carousel-inner .active.carousel-item-left,
  #hero .carousel-inner .active.carousel-item-right {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero .carousel-indicators li {
    cursor: pointer;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: $color_white;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 0;
    background: $color_redSolkos1;
  }
  
  #hero .btn-get-started:hover {
    background: $color_redSolkos3;
  }
  
  @media (max-width: 768px) {
    #hero h2 {
      font-size: 28px;
    }
  }
  
  @media (min-width: 1024px) {
    #hero p {
      width: 60%;
    }
    #hero .carousel-control-prev, #hero .carousel-control-next {
      width: 5%;
    }
  }