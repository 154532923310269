/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: $color_globalFonts;
  font-size: 14px;
  background: $color_ligthGray5;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: $color_ligthGray4;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: $color_secondaryFonts;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: $color_globalFonts;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.access-footer {
  display: flex;
  justify-content: flex-end;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: $color_redSolkos2;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: $color_secondaryFonts;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: $color_blue;
}

@media (min-width: 768px) {
  .sty-footer {
    display: flex;
  }
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: $color_globalFonts;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: $color_blackBlue;
  color: $color_white;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: $color_blackBlueHover;
  color: $color_white;
  text-decoration: none;
}
