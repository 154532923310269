@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import "../../styles/variables.scss";

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
    padding-bottom: 30px;
  }
  
  /* .about .container {
    box-shadow: $shadow_cards;
  } */
  
  .about .video-box img {
    padding: 15px 0;
  }
  
  .about .section-title p {
    text-align: left;
    font-style: italic;
    color: $color_globalFonts;
  }
  
  .about .about-content {
    padding: 40px;
  }
  
  
  .about .video-box {
    position: relative;
  }
  
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient($color_redSolkos1 50%, $color_redSolkosOpacity 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid $color_white;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid $color_redSolkosOpacity2;
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about .play-btn:hover::after {
    border-left: 15px solid $color_redSolkos3;
    transform: scale(20);
  }
  
  .about .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid $color_white;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # About Lists
  --------------------------------------------------------------*/
  .about-lists {
    padding: 40px;
  }
  
  .about-lists .row {
    overflow: hidden;
  }
  
  .about-lists .content-item {
    padding: 40px;
    border-left: 1px solid $color_ligthGray;
    border-bottom: 1px solid $color_ligthGray;
    margin: -1px;
  }
  
  .about-lists .content-item span {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: $color_ligthGray3;
  }
  
  .about-lists .content-item h4 {
    font-size: 28px;
    font-weight: 400;
    padding: 0;
    margin: 20px 0;
  }
  
  .about-lists .content-item p {
    color: $color_secondaryFonts;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  
  @media (max-width: 768px) {
    .about-lists .content-item {
      padding: 40px 0;
    }
  }