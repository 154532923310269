/*--------------------------------------------------------------
# Productivity list
--------------------------------------------------------------*/
@import "../../styles/variables.scss";

.productivity {
  padding: 40px;
}

.productivity .row {
  overflow: hidden;
}

.productivity .content-item {
  padding: 40px;
  border-left: 1px solid shades($color_redSolkos1,l2);
  border-bottom: 1px solid shades($color_redSolkos1,l2);
  margin: -1px;
}

.productivity .content-item span {
  display: block;
  font-size: 24px;
  font-weight: 400;
  color: $color_redSolkos3;
}

.productivity .content-item h4 {
  font-size: 28px;
  font-weight: 400;
  padding: 0;
  margin: 20px 0;
}

.productivity .content-item p {
  color: $color_globalFonts;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .productivity .content-item {
    padding: 40px 0;
  }
}