
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
    padding: 30px;
    background: $color_blackBlue;
    border-radius: 4px;
    color: $color_white;
  }
  
  .why-us .content h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .why-us .content p {
    margin-bottom: 30px;
  }
  
  .why-us .content .more-btn {
    display: inline-block;
    background: $color_redSolkos1;
    padding: 6px 30px 8px 30px;
    color: $color_white;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
  }

  .why-us .whyUs-btn{
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
  }
  
  .why-us .content .more-btn i {
    font-size: 14px;
  }
  
  .why-us .content .more-btn:hover {
    
    background: $color_redSolkos3;
  }
  
  .why-us .icon-boxes .icon-box {
    text-align: center;
    border-radius: 15px;
    background: $color_white;
    box-shadow: $shadow_cards;
    padding: 40px 30px;
    width: 100%;
    transition: 0.3s;
  }
  
  .why-us .icon-boxes .icon-box i {
    font-size: 40px;
    color: $color_blackBlue;
    margin-bottom: 30px;
  }
  
  .why-us .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 30px 0;
  }

  .why-us .icon-boxes .icon-box hr {
    max-width: 30%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    border-top: 3px solid $color_redSolkos1;
  } 
  
  .why-us .icon-boxes .icon-box p {
    font-size: 15px;
    color: $color_secondaryFonts;
  }
  
  .why-us .icon-boxes .icon-box:hover {
    box-shadow: $shadow_cardsHover;
  }