/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    padding: 40px 20px;
    transition: all ease-in-out 0.3s;
    background: $color_white;
    border-radius: 15px;
  }
  
  .services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    border: 1px solid $color_blackBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
    color: $color_blackBlue;
  }
  
  .services .icon-box .icon i {
    font-size: 28px;
  }
  
  .services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .services .icon-box h4 a {
    color: $color_titleFonts;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover {
    border-color: $color_white;
    box-shadow: $shadow_blackLight;
  }
  
  .services .icon-box:hover h4 a {
    color: $color_blackBlue;
  }
  
  .services .icon-box:hover .icon {
    color: $color_white;
    background: $color_blackBlue;
  }