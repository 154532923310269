// Utilities
@import "../../styles/variables.scss";

// Components
@import "../../components/Header/styles.scss";
@import "../../components/Footer/styles.scss";
@import "../../components/BackToUp/styles.scss";

.stickyAlert{
    position: fixed;
    top: 124px;
    left: 0%;
    width: 45%;
    z-index: 99999999;
}

.alert.alert-dismissible{
  position: absolute!important;
}

.toast{
  max-width: 700px;
}
.toast-body{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}

.form-registration{
    /* display: flex;
    flex-direction: column;
    margin: 0px 60% 24px;
    width: 40%;
    height: 500px;
    padding: 30px 40px;
    background: #FFFFFF;
    border-radius: 1px;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.08); 
    box-sizing: border-box;
    color: #444444; */
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.08); 
    background-color: #fff;
    padding: 25px;
    max-width: 470px;
    width: 100%;
    margin: 30px auto;
    border-radius: 35px;
}

section.registration{
  padding: 4rem 0;
}

@media (max-width: 999px){
  #hero .carousel-content.container .sty-textProductRegister{
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    padding-top: 20%;
  }
}

@media (max-width: 750px){
  #hero .carousel-content.container .sty-textProductRegister {
    padding-top: 40%;
  } 
}


#hero .carousel-content.container .sty-textProductRegister {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1000px){
  .sty-textProductRegister {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
    padding-top: 9%;
  }
}

@media (min-width: 1000px){
  .carousel-container.carousel-content .container {
    text-align: left;
  }
}

@media (min-width: 1000px){
  .carousel-container.carousel-content .text-holder {
    max-width: 570px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

.carousel-container.carousel-content .text-holder {
  display: block;
  position: relative;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  margin: auto 0;
}


.carousel-content, #hero .carousel-container .sty-textProductRegister h1, #hero .carousel-container .sty-textProductRegister p{
  text-align: center;
}

@media (max-width: 1000px){
  #hero .carousel-container .sty-textProductRegister p{
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  }
}

#hero .carousel-container .sty-textProductRegister p{
  padding-left: 0;
  padding-right: 0;
}


@media (min-width: 1000px){
  #hero .carousel-container p {
    font-size: 18px;
    line-height: 26px;
    width: 52%!important;
    text-align: left!important;
  }
  #hero .carousel-container .sty-textProductRegister h1 {
    width: 52%!important;
    font-size: 65px;
    line-height: 75px;
    text-align: left!important;
  }
}

.sty-textProductRegister p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 1000px){
  .carousel-container .h1+p {
    margin-top: 22px;
  }
}

#hero .carousel-container p {
  width: 100%;
}

#hero .carousel-container.sty-product {
  display: block;
  justify-content: left!important;
  align-items: start!important;
}

@media (min-width: 750px){
  .registration .about-registration {
    position: relative;
    // min-height: 800px;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    max-width: 525px;
    width: 100%;
    margin-right: 140px;
    text-align: left;
  }

  .registration .about-registration ul li.row div, .registration .about-registration div h3 {
    text-align: left!important;
  }

}

.registration .about-registration h3 {
  padding-bottom: 1.5rem;
}

.registration .about-registration ul li div, .registration .about-registration h3 {
  text-align: center;
}

.registration .about-registration ul {
  list-style: none;
}



@media (min-width: 1000px){
  .registration .sty-productRegister .form-registration {
    position: absolute;
    top: 132px;//calc(226px - 100vh);
    z-index: 1;
    right: 3%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }
}

.form-registration label {
  color: #444444;
}

.sty-changeFormat{
  color: #5c768d;
}
.sty-changeFormat:hover{
  color: #9eccf4 ;
  transition: 0.2s;
}


@media (max-width: 999px){
  #hero .container .sty-iconProdct{
    font-size: 150px!important;
    color: #fafafa8a!important;
    padding-top: 15%!important;
  }
  
}
#hero .container .sty-iconProdct{
  font-size: 0px;
  color: transparent;
}


/* #hero .carousel-content.container {
    padding: 0!important;
} */

/* #hero .carousel-container {
  align-items: start!important;
} */










.sty-header {
    text-align: center;
    padding: 40px 0px;
    color: $color_white;
  }
  
  .sty-titleHeader {
    display: inline-block;
    height: 40px;
    position: relative;
    user-select: none;
  }
  
  .loginCard {
    display: flex;
    flex-direction: column;
    margin: 0px auto 24px;
    width: 400px;
    padding: 32px 40px;
    background: $color_white;
    border-radius: 3px;
    box-shadow: $shadow_cards;
    box-sizing: border-box;
    color: $color_globalFonts;
  }
  
  .titleContentCard {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;
    color: $color_globalFonts;
    font-size: 16px;
  }
  
  .loginCard h5 {
    color: $color_globalFonts;
    font-weight: 600;
    font-size: 1em;
  }
  
  .loginCard a,
  .loginCard .toggleRegistry {
    color: $color_blue;
    cursor: pointer;
  }
  
  .loginForm {
    margin: 0px;
  }

  .sty-specialAlert {
    width: 89%;
    position: absolute;
  }

  /* STYLES FOR STY INPUT FORM BUTTON */
  .sty-inputForm:first-child {
    margin-top: 0px;
  }

  .form-registration .sty-inputForm{
    background-color: $color_ligthGray5 !important;
    border-color: shades($color_ligthGray5, "d1") !important;
    color: $color_blackBlueBackground !important;
    font-size: 14px !important;
    border-radius: 15px!important;
    border-style: solid;
  
    &:hover {
      background-color: $color_white !important;
      box-shadow: $shadow_input !important;
      border-color: shades($color_ligthGray5, "d2") !important;
    }
    /* &:focus {
      background-color: $color_white !important;
      box-shadow: $shadow_input !important;
    } */
    
  }
  /* END STYLES FOR STY INPUT FORM BUTTON */

  /* STYLES FOR THE MARKER BAR TO PASSWORD VALIDATION*/
.input-password__marker {
  display: flex;
  margin-bottom: 15px;
}

.input-password__marker > div:first-child {
  margin-left: 0;
}

.input-password__marker > div:last-child {
  margin-right: 0;
}

.input-password__marker > div {
  background-color: #F2F2F2;
  border-radius: 10px;
  height: 8px;
  width: 33%;
  flex: 1;
  margin: 0 4px;
}

.input-password__marker > div.danger {
  background-color: #F79682;
}

.input-password__marker > div.warning {
  background-color: #F7BC1C;
}

.input-password__marker > div.success {
  background-color: #17D499;
}

/* END STYLES FOR THE MARKER BAR TO PASSWORD VALIDATION*/

  .form-registration {
    padding-bottom: 30px;
  }

  .form-registration label {
    margin-bottom: 0.1rem!important;
  }

  .form-registration .logo {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .form-registration .react-tag-input{
    border-radius: 15px!important;
  }

  .form-registration .loginSubmit {
    border-radius: 15px!important;
  }

  .form-registration .form-group:last-child {
    padding-bottom: 30px;
  }

  .registration{
    background-color: #6f7686;
  }

  .about-registration{
    color: #fafafa;
  }
  
  .sty-invalidPhone {
    &.disabled {
      background-color: shades($color_ligthGray5, "d1") !important;
      border-color: shades($color_ligthGray5, "d2") !important;
    }
    font-size: 14px !important;
    border-color: shades($color_ligthGray5, "d1") !important;
    border-radius: 15px!important;
  border-style: solid!important;
    background-color: $color_white !important;
    &:hover {
      border-color: shades($color_ligthGray5, "d2") !important;
    }
    &:focus {
      box-shadow: $shadow_input !important;
    }
  }
  
  .react-tel-input .invalid-number-message {
    background: transparent!important;
    font-size: 12px;
    font-weight: 500;
    z-index: auto!important;
    left: 65px!important;
    top: -9px!important;
  }
  
  .sty-resendCode {
    color: $color_blue;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .countCaptcha {
    font-size: 36px;
    display: block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color_ligthGray;
    border-color: shades($color_secondaryFonts, "d2") !important;
  }
  
  .panelNotification{
    padding: 18px;
    height: 110px;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    background-color: $color_ligthGray;
    border-color: shades($color_secondaryFonts, "d2") !important;
  }
  
  .panelSolkos{
    padding: 18px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    background-color: $color_ligthGray;
    border-color: shades($color_secondaryFonts, "d2") !important;
  
  }
  
  .panelSolkos p{
    margin-bottom: 0!important;
  }
  
  .loginSubmit {
    height: 40px !important;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    background: $color_blackBlue;
    cursor: default;
    padding: 0px 8px;
    vertical-align: middle;
    width: 100%;
    outline: none !important;
    color: $color_white !important;
  
    &:hover {
      cursor: pointer;
      background: $color_blackBlueHover;
    }
    &:focus {
      box-shadow: $shadow_input;
    }
  }
  
  .loginSubmit span {
    -webkit-box-pack: center;
    justify-content: center;
    display: flex !important;
  }
  
  .centerSpan {
    align-self: center;
    display: inline-flex;
    flex-wrap: nowrap;
    max-width: 100%;
    position: relative;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
  }
  
  .loadElement {
    animation: spin-animation 1.2s infinite;
    display: inline-block;
  
    @keyframes spin-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
  
  .social-login {
    margin-top: 16px;
  }
  
  .social-or {
    color: $color_ligthGray6;
    //content: attr(data-i18n-or);
    display: block;
    font-size: 11px;
    line-height: 1;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
  }
  
  .socialAccess {
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 100%;
    padding: 0px 35px 0px 35px;
    margin: 0;
  }
  .socialAccess li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    border-width: 0px;
    border-radius: 3px;
    background: $color_white;
    box-shadow: $shadow_black2 !important;
  
    &:hover {
      background: $color_ligthGray3 !important;
      box-shadow: $shadow_black2 !important;
    }
  }
  
  .socialIcons {
    height: 18px;
    width: 18px;
  }
  
  .loginFooter {
    color: $color_secondaryFonts;
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid $color_ligthGray5;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }
  
  .centerList ul{
    display:flex;
    font-size: 14px;
    justify-content: space-around!important;
    list-style: none;
    padding: 0px;
  }
  
  .loginFooter ul {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0px;
  }