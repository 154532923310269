
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: $shadow_blackGradient, url("../../img/cta-bg.jpg") fixed center center;
    background-size: cover;
    padding: 25px 0;
  }
  
  .cta h3 {
    color: $color_white;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: $color_white;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid $color_white;
    color: $color_white;
  }
  
  .cta .cta-btn:hover {
    background: $color_redSolkos1;
    border: 2px solid $color_white;
  }